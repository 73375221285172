import React from 'react';
import MainNavigation from './components/header/MainNavigation';
import Hero from './components/hero/Hero';
import Services from './components/services/Services';
import PriceList from './components/priceList/PriceList';
import Contact from './components/contact/Contact';
import Appointment from './components/appointment/Appointment';
import Footer from './components/footer/Footer';

function App() {
	return (
		<>
			<MainNavigation />
			<Hero />
			<Services />
			<PriceList />
			<Contact />
			<Appointment />
			<Footer />
		</>
	);
}

export default App;
