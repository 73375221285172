import React from "react";
import FooterInfo from "./FooterInfo";
import FooterMenu from "./FooterMenu";
import "./Footer.scss";

const Footer = () => {
	return (
		<footer>
			<FooterInfo />
			<FooterMenu />
		</footer>
	);
};

export default Footer;
