import React from "react";
import Modal from "react-bootstrap/Modal";
import "./ModalLegal.scss";

const ImpressumText = () => {
	return (
		<div className="mt-5 mb-5">
			<p className="mb-2">
				<strong>Nails 93 Leipzig</strong>
			</p>
			<p className="mb-2">
				<strong>Adresse:</strong> Zschochersche Straße 36, 04229 Leipzig
			</p>
			<p className="mb-2">
				<strong>E-Mail-Adresse:</strong> beautynailsleipzig888@gmail.com
			</p>
			<p className="mb-2">
				<strong>Telefon:</strong> 0341 - 974 866 40
			</p>
			<p className="mb-2">
				<strong>Amtsgericht:</strong> Leipzig
			</p>
			<p className="mb-2">
				<strong>Verantwortlich für den Inhalt:</strong> Thi Yen Do
			</p>
			<p className="mb-2">
				<strong>USt.- Id:</strong> DE 232/213/20921
			</p>
		</div>
	);
};

const DatenschutzText = () => {
	return (
		<div className="mt-5 mb-5">
			<p className="mb-2">Datenschutz-Texte...</p>
		</div>
	);
};

const ModalLegal = (props) => {
	const LEGAL_TEXT = {
		impressum: <ImpressumText />,
		datenschutz: <DatenschutzText />,
	};

	return (
		<Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">{props.title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>{LEGAL_TEXT[props.title.toLowerCase()]}</Modal.Body>
		</Modal>
	);
};

export default ModalLegal;
