import React from "react";
import "./ContactInfo.scss";

const ContactInfo = () => {
	const CONTACT_INFO = [
		{
			type: "address",
			text: "Zschochersche Straße 36, 04229 Leipzig",
			icon: "/assets/icons/nails93-location-gold.svg",
			link: "https://maps.google.com/maps?ll=51.33045,12.33737&z=20&t=m&hl=en-US&gl=US&mapclient=embed&cid=13487782329479998140",
		},
		{
			type: "email",
			text: "beautynailsleipzig888@gmail.com",
			icon: "/assets/icons/nails93-email-gold.svg",
			link: "mailto:beautynailsleipzig888@gmail.com",
		},
		{
			type: "phone",
			text: "0341 - 974 866 40",
			icon: "/assets/icons/nails93-telefon-gold.svg",
			link: "tel:+4934197486640",
		},
		{
			type: "chat",
			text: "0179 - 595 19 10",
			icon: "/assets/icons/nails93-chat-gold.svg",
			link: "https://wa.me/+491795951910",
		},
	];

	return (
		<div className="contact-info">
			{CONTACT_INFO.map((item, item_index) => {
				return (
					<a key={item_index} className="contact-info__item" href={item.link} target="_blank" rel="noreferrer">
						<img className="contact-info__item--icon" src={item.icon} alt={`Nails 93 Leipzig`} />
						<span className="contact-info__item--text">
							{item.type === "email" ? item.text.split("@")[0] : item.text}
							{item.type === "email" ? <>&shy;</> : ""}
							{item.type === "email" ? item.text.split("@")[1] : ""}
						</span>
					</a>
				);
			})}
		</div>
	);
};

export default ContactInfo;
