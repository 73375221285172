import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "./MainNavigation.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFeatherPointed } from "@fortawesome/free-solid-svg-icons";
import { TREATWELL_LINK } from "../global/DummyData";

const MainNavigation = () => {
	const HAS_ICON = false;
	const THE_ICON = faFeatherPointed;
	const [navExpand, setNavExpand] = useState(false);

	const toggle_navbar = (expanded) => {
		setNavExpand(!expanded);
	}

	return (
		<Navbar id="main_navigation" expand="lg" sticky="top" expanded={navExpand}>
			<Container fluid>
				<Navbar.Brand href="#">
					<img src="/assets/logos/Nails93-Logo_v3.svg" alt="Nails 93 Leipzig" />
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="main_menu" onClick={() => toggle_navbar(navExpand)} />
				<Navbar.Collapse id="main_menu" className="gap-5">
					<Nav className="me-auto my-2 my-lg-0">
						<Nav.Link href="#service" onClick={() => setNavExpand(false)}>
							{HAS_ICON ? <FontAwesomeIcon icon={THE_ICON} className="nav-icon nav-icon-front" /> : <></>}
							Service
							{HAS_ICON ? <FontAwesomeIcon icon={THE_ICON} className="nav-icon nav-icon-back" /> : <></>}
						</Nav.Link>
						<Nav.Link href="#preisliste" onClick={() => setNavExpand(false)}>
							{HAS_ICON ? <FontAwesomeIcon icon={THE_ICON} className="nav-icon nav-icon-front" /> : <></>}
							Preisliste
							{HAS_ICON ? <FontAwesomeIcon icon={THE_ICON} className="nav-icon nav-icon-back" /> : <></>}
						</Nav.Link>
						<Nav.Link href="#kontakt" onClick={() => setNavExpand(false)}>
							{HAS_ICON ? <FontAwesomeIcon icon={THE_ICON} className="nav-icon nav-icon-front" /> : <></>}
							Kontakt
							{HAS_ICON ? <FontAwesomeIcon icon={THE_ICON} className="nav-icon nav-icon-back" /> : <></>}
						</Nav.Link>
					</Nav>
					<div className="btn-desktop">
						<Button size="lg" className="btn-termin" href={TREATWELL_LINK} target="_blank">
							Jetzt Termin vereinbaren
						</Button>
					</div>
				</Navbar.Collapse>
			</Container>
			{/* <Container fluid className="btn-mobile">
				<Button variant="outline-info" size="lg" className="fs-5 btn-termin">
					Jetzt Termin vereinbaren
				</Button>
			</Container> */}
		</Navbar>
	);
};

export default MainNavigation;
