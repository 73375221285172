export const DUMMY_DATA = {
	treatwell_link: "https://nails93-and-beauty-nagelstudio.salonized.com/widget_bookings/new",
	company: {
		name: "Nails 93 Leipzig",
		owner: "Thi Yen Do",
		amtsgericht: "Leipzig",
		ust_id: "DE 232/213/20921",
		address: {
			street: "Zschochersche Straße",
			house_nr: "36",
			postal_code: "04229",
			city: "Leipzig",
			country: "Deutschland",
		},
		contact_infos: [
			{
				type: "address",
				text: "Zschochersche Straße 35, 04229 Leipzig",
				icon: "/assets/icons/nails93-location-64.png",
				link: "https://maps.google.com/maps?ll=51.33045,12.33737&z=20&t=m&hl=en-US&gl=US&mapclient=embed&cid=13487782329479998140",
			},
			{
				type: "email",
				text: "beautynailsleipzig888@gmail.com",
				icon: "/assets/icons/nails93-email-64.png",
				link: "mailto:beautynailsleipzig888@gmail.com",
			},
			{
				type: "phone",
				text: "0341 - 974 866 40",
				icon: "/assets/icons/nails93-telefon-64.png",
				link: "tel:034197486640",
			},
			{
				type: "chat",
				text: "0179 - 595 19 10",
				icon: "/assets/icons/nails93-chat-64.png",
				link: "https://wa.me/01795951910",
			},
		],
	},
	products: [
		{
			category_name: "Nails",
			category_icon: "/assets/icons/nails93-preisliste-nails-128.png",
			price_table: [
				{
					item_name: "UV-Gel oder Acryl",
					item_large: false,
					item_main_price: "",
					item_desc: "",
					item_details: [
						{ name: "Neumodellage", price: "ab 35 €" },
						{ name: "Farbe/Glitzer", price: "ab 35 €" },
						{ name: "Babybommer/French", price: "ab 35 €" },
						{ name: "Natur/Makeup", price: "ab 35 €" },
					],
					item_extras: [
						{
							extra_name: "Extras",
							extra_main_price: "",
							extra_desc: "",
							extra_details: [
								{ name: "Verlängerung mit Tip", price: "5 €" },
								{ name: "Extra Länge", price: "5 €" },
								{ name: "Steichen (pro Nagel)", price: "1 €" },
								{ name: "Design (pro Nagel)", price: "ab 3 €" },
								{ name: "Reparatur (pro Nagel)", price: "ab 3 €" },
								{ name: "Ablösen (pro Nagel)", price: "10 €" },
							],
						},
					],
				},
				{
					item_name: "Pediküre",
					item_large: false,
					item_main_price: "25 €",
					item_desc: "Fußbad, kürzen, feilen, Nagel- und Hornhaut&shy;entfernung, Peeling, Creme",
					item_details: [
						{ name: "Neumodellage", price: "ab 35 €" },
						{ name: "Farbe/Glitzer", price: "ab 35 €" },
						{ name: "Babybommer/French", price: "ab 35 €" },
						{ name: "Natur/Makeup", price: "ab 35 €" },
					],
				},
				{
					item_name: "Maniküre",
					item_large: false,
					item_main_price: "10 €",
					item_desc: "Fußbad, kürzen, feilen, Nagelhaut&shy;entfernung, Pflegeöl",
					item_details: [
						{ name: "mit Nagellack", price: "ab 15 €" },
						{ name: "mit Shellac", price: "ab 25 €" },
					],
				},
			],
		},
		{
			category_name: "Lash extensions",
			category_icon: "/assets/icons/nails93-preisliste-wimpern-128.png",
			price_table: [
				{
					item_name: "Neuauflage",
					item_large: false,
					item_main_price: "",
					item_desc: "",
					item_details: [
						{ name: "Classic 1:1", price: "60 €" },
						{ name: "Volumen 2D-4D", price: "70 €" },
					],
					item_extras: [
						{
							extra_name: "Auffüllen (classic)",
							extra_main_price: "",
							extra_desc: "",
							extra_details: [
								{ name: "2 Wochen", price: "30 €" },
								{ name: "3 Wochen", price: "40 €" },
								{ name: "4 Wochen", price: "55 €" },
							],
						},
						{
							extra_name: "Auffüllen (volumnen)",
							extra_main_price: "",
							extra_desc: "",
							extra_details: [
								{ name: "2 Wochen", price: "35 €" },
								{ name: "3 Wochen", price: "45 €" },
								{ name: "4 Wochen", price: "60 €" },
							],
						},
					],
				},
				{
					item_name: "",
					item_large: true,
					item_main_price: "",
					item_desc: "",
					item_details: [
						{ name: "Wimpernlifting", price: "ab 35 €" },
						{ name: "Augenbraunen (zupfen)", price: "10 €" },
						{ name: "Augenbraunen (Färben)", price: "10 €" },
						{ name: "Ablösen", price: "15 €" },
						{ name: "Permanent make up", price: "nach Vereinbarung" },
					],
				},
			],
		},
	],
};

export const TREATWELL_LINK = "https://nails93-and-beauty-nagelstudio.salonized.com/widget_bookings/new";