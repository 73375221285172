import React from "react";
import Section from "../global/Section";
import "./Services.scss";

const Services = () => {
	const title = "Service";
	const short_desc = "Bei uns in Studio stehen alle gängigen und aktuellen Nailart-Techniken und Trends Ihnen zur Verfügung. Wir arbeiten mit modernen Methoden und verfügen über langjährige Erfahrungen.";
	return (
		<Section id="service" main_class="services" title={title} short_desc={short_desc}>
			<div className="services__list">
				<div className="services__list--item">
					<img className="services__list--item__img" src="/assets/img/nails93-services-01_v1.webp" alt="Nails 93 Leipzig Wimpernverlaenderung" />
					<div className="services__list--item__title">
						<h4>Wimpern&shy;verländerung</h4>
					</div>
				</div>
				<div className="services__list--item">
					<img className="services__list--item__img" src="/assets/img/nails93-services-02_v1.webp" alt="Nails 93 Leipzig Nageldesign" />
					<div className="services__list--item__title">
						<h4>Nageldesign</h4>
					</div>
				</div>
				<div className="services__list--item">
					<img className="services__list--item__img" src="/assets/img/nails93-services-03_v1.webp" alt="Nails 93 Leipzig Manikuere" />
					<div className="services__list--item__title">
						<h4>Maniküre</h4>
					</div>
				</div>
				<div className="services__list--item">
					<img className="services__list--item__img" src="/assets/img/nails93-services-04_v1.webp" alt="Nails 93 Leipzig Pedikuere" />
					<div className="services__list--item__title">
						<h4>Pediküre</h4>
					</div>
				</div>
				<div className="services__list--item">
					<img className="services__list--item__img" src="/assets/img/nails93-services-05_v1.webp" alt="Nails 93 Leipzig Permanent Makeup" />
					<div className="services__list--item__title">
						<h4>Permanent Makeup</h4>
					</div>
				</div>
			</div>
		</Section>
	);
};

export default Services;
