import React from "react";
import Section from "../global/Section";
// import Map from "./Map";
import MapSimple from "./MapSimple";
import ContactInfo from "./ContactInfo";
// import ContactForm from "./ContactForm";
import './Contact.scss';

const Contact = () => {
	const title = "Kontaktiere uns";
	const desc = "Wir freuen uns auf Ihren Besuch in unserem Studio in Leipzig!";
    
	return (
		<Section id="kontakt" main_class="contact" title={title} short_desc={desc}>
			<div className="contact-details">
				<div className="contact-details__general">
                    <ContactInfo />
                    {/* <ContactForm /> */}
                </div>
				<div className="contact-details__maps">
                    {/* <Map /> */}
					<MapSimple />
                </div>
			</div>
		</Section>
	);
};

export default Contact;
