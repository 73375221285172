import React from "react";
import Button from "react-bootstrap/esm/Button";
import "./Hero.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import { TREATWELL_LINK } from "../global/DummyData";

const Hero = () => {
	return (
		<div className="hero">
			<div className="hero__content">
				<div className="hero__content--slogan">
					<img src="/assets/img/slogan_01.webp" alt="Nails93 & Beauty" />
				</div>
				<div className="hero__content--title">
					<h1>Erlebe das beste Nailserlebnis</h1>
				</div>
				<div className="hero__content--buttons">
					<Button size="lg" href="#service">
						Unsere Service erfahren
					</Button>
					<Button size="lg" className="btn-termin" href={TREATWELL_LINK} target="_blank">
						<FontAwesomeIcon icon={faCalendarDays} />
						Jetzt Termin vereinbaren
					</Button>
				</div>
			</div>
			{/* <div className="hero__media">
				<div className="hero__media--figure"></div>
			</div> */}
		</div>
	);
};

export default Hero;
