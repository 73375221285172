import React from "react";
import './MapSimple.scss';

const MapSimple = () => {
	const MAP_SIMPLE = {
		link: "https://maps.google.com/maps?ll=51.33045,12.33737&z=20&t=m&hl=en-US&gl=US&mapclient=embed&cid=13487782329479998140",
		image: "/assets/img/map-simple_v1.webp",
		view_larger_title: "in Google Maps anzeigen"
	}

	return (
		<a className="map-simple" href={MAP_SIMPLE.link} target="_blank" rel="noreferrer">
			<img src={MAP_SIMPLE.image} alt="Nails 93 Leipzig" />
			<div className="view-larger">
				<span>{MAP_SIMPLE.view_larger_title}</span>
			</div>
		</a>
	);
};

export default MapSimple;
