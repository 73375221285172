import React from "react";
import Badge from "react-bootstrap/Badge";
import Section from "../global/Section";
import "./PriceList.scss";

const PRODUCTS = [
	{
		category_name: "Maniküre",
		category_icon: "/assets/icons/nails93-preisliste-nails-gold.svg",
		price_table: [
			{
				item_name: "Maniküre",
				item_large: false,
				item_main_price: "",
				item_desc: "",
				item_price_titles: [],
				item_details: [
					{ name: "Maniküre inkl. Hand-Massage", price: "15€" },
					{ name: "Maniküre mit Nagellack", price: "18€" },
					{ name: "Nur Nagellack", price: "7€" },
					{ name: "Ablösen Nagellack", price: "5€" },
				],
				// item_extras: [
				// 	{
				// 		extra_name: "Extras",
				// 		extra_main_price: "",
				// 		extra_desc: "",
				// 		extra_details: [
				// 			{ name: "Extra Länge", price: "+5 €" },
				// 			{ name: "Steichen (pro Stück)", price: "ab 0,5 €" },
				// 			{ name: "Design (pro Nagel)", price: "ab 3 €" },
				// 			{ name: "Reparatur (pro Nagel)", price: "ab 3 €" },
				// 			{ name: "Ablösen", price: "10 €" },
				// 			{ name: "Chrome Effekt", price: "ab 15 €" },
				// 		],
				// 	},
				// ],
			},
			{
				item_name: "Shellac",
				item_large: false,
				item_main_price: "",
				item_desc: "",
				item_price_titles: [],
				item_details: [
					{ name: "Shellac NEU ohne Maniküre", price: "28€" },
					{ name: "Shellac mit Maniküre", price: "33€" },
					{ name: "Shellac mit Maniküre + French", price: "37€" },
					{ name: "Shellac ablösen, Maniküre und Shellac Neu", price: "40€" },
					{ name: "Shellac ablösen, Maniküre und Shellac Neu + French", price: "43€" },
					{ name: "Shellac ablösen", price: "15€" },
				],
			},
			{
				item_name: "Acryl/Gel",
				item_large: false,
				item_main_price: "",
				item_desc: "",
				item_price_titles: ["Neu", "Auffüllen"],
				item_details: [
					{ name: "Natur", price: "35€", second_price: "30€" },
					{ name: "Natur Chrom", price: "45€", second_price: "40€" },
					{ name: "Farbe, French (Schablon), Glitzerfarbe, Ombre", price: "43€", second_price: "38€" },
					{ name: "French gemalt", price: "50€", second_price: "45€" },
					{ name: "Ombre Farbe", price: "50€", second_price: "45€" },
					{ name: "Cateyes, Farbe mit Chrom", price: "+10€", second_price: "+10€" },
					{ name: "Ablösen", price: "", second_price: "15€" },
					{ name: "+ mit Maniküre", price: "", second_price: "+10€" },
				],
			},
			{
				item_name: "Verlängerung",
				item_large: false,
				item_main_price: "",
				item_desc: "",
				item_price_titles: [],
				item_details: [
					{ name: "+ Verlängerung - mittlere Länge bei Neumodellage", price: "5€" },
					{ name: "+ Verlängerung - lange Länge bei Neumodellage", price: "10€" },
					{ name: "+ Verlängerung - Extra lange Länge bei Neumodellage", price: "15€" },
				],
			},
			{
				item_name: "Design",
				item_large: false,
				item_main_price: "",
				item_desc: "",
				item_price_titles: ["", ""],
				item_details: [
					{ name: "Design 2 Farben", price: "", second_price: "5€" },
					{ name: "Design Glitzer Pulver, Inlays, Sticker und Co.", price: "je Finger", second_price: "ab 3€" },
					{ name: "Design Overlays in 3D, Stamping und Co.", price: "je Finger", second_price: "ab 3€" },
					{ name: "Design Strasssteine pro", price: "je Finger", second_price: "ab 0,5€" },
				],
			},
		],
	},
	{
		category_name: "Pediküre",
		category_icon: "/assets/icons/nails93-preisliste-pedikuere-gold.svg",
		price_table: [
			{
				item_name: "Pediküre",
				item_large: false,
				item_main_price: "",
				item_desc: "",
				item_price_titles: [],
				item_details: [
					{ name: "Fußnägel lackieren mit Shellac inkl. Farbe", price: "25€" },
					{ name: "Fußnägel lackieren mit Shellac inkl. French", price: "30€" },
					{ name: "Pediküre", price: "30€" },
					{ name: "Pediküre mit Lack", price: "35€" },
					{ name: "Pediküre mit Shellac inkl. Farbe", price: "40€" },
					{ name: "Pediküre mit Shellac inkl. French", price: "45€" },
					{ name: "Pediküre mit UV-Gel/ Acryl inkl. French/Ombre", price: "60€" },
					{ name: "Pediküre mit UV-Gel/ Acryl inkl. Farbe", price: "55€" },
				],
				// item_extras: [
				// 	{
				// 		extra_name: "Auffüllen (classic)",
				// 		extra_main_price: "",
				// 		extra_desc: "",
				// 		extra_details: [
				// 			{ name: "2 Wochen", price: "35 €" },
				// 			{ name: "3 Wochen", price: "45 €" },
				// 			{ name: "4 Wochen", price: "60 €" },
				// 		],
				// 	},
				// 	{
				// 		extra_name: "Auffüllen (volumnen)",
				// 		extra_main_price: "",
				// 		extra_desc: "",
				// 		extra_details: [
				// 			{ name: "2 Wochen", price: "40 €" },
				// 			{ name: "3 Wochen", price: "50 €" },
				// 			{ name: "4 Wochen", price: "65 €" },
				// 		],
				// 	},
				// ],
			},
			{
				item_name: "Zehenmodellage",
				item_large: false,
				item_main_price: "",
				item_desc: "",
				item_price_titles: [],
				item_details: [
					{ name: "Acryl oder Gel Natur", price: "35€" },
					{ name: "Acryl oder Gel Farbe", price: "40€" },
					{ name: "Acryl oder Gel French	/Ombre", price: "45€" },
				],
			},
			{
				item_name: "Weitere Leistungen",
				item_large: false,
				item_main_price: "",
				item_desc: "",
				item_price_titles: [],
				item_details: [
					{ name: "kürzen, feilen der Fingernägel / Zehennägel", price: "7€" },
					{ name: "Reparieren (nach Aufwand) ab", price: "5€" },
					{ name: "Farbe ändern (innerhalb von 7 Tagen nach Ihrem Termin)", price: "15€" },
				],
			},
		],
	},
	{
		category_name: "Wimpern & Augenbrauen",
		category_icon: "/assets/icons/nails93-preisliste-wimpern-gold.svg",
		price_table: [
			{
				item_name: "Augenbrauen Formen & Design",
				item_large: false,
				item_main_price: "",
				item_desc: "",
				item_price_titles: [],
				item_details: [
					{ name: "Augenbrauen zupfen", price: "10€" },
					{ name: "Augenbrauen färben", price: "10€" },
					{ name: "Augenbrauen zupfen & färben", price: "15€" },
				],
				// item_extras: [
				// 	{
				// 		extra_name: "Auffüllen (classic)",
				// 		extra_main_price: "",
				// 		extra_desc: "",
				// 		extra_details: [
				// 			{ name: "2 Wochen", price: "35 €" },
				// 			{ name: "3 Wochen", price: "45 €" },
				// 			{ name: "4 Wochen", price: "60 €" },
				// 		],
				// 	},
				// 	{
				// 		extra_name: "Auffüllen (volumnen)",
				// 		extra_main_price: "",
				// 		extra_desc: "",
				// 		extra_details: [
				// 			{ name: "2 Wochen", price: "40 €" },
				// 			{ name: "3 Wochen", price: "50 €" },
				// 			{ name: "4 Wochen", price: "65 €" },
				// 		],
				// 	},
				// ],
			},
			{
				item_name: "Wimpernverlängerung",
				item_large: false,
				item_main_price: "",
				item_desc: "",
				item_price_titles: [],
				item_details: [
					{ name: "Wimpernverlängerung 1:1 Technik", badge: "Neu", price: "70€" },
					{ name: "Wimpernverlängerung auffüllen 1:1 Technik bis 2 Wochen", price: "40€" },
					{ name: "Wimpernverlängerung auffüllen 1:1 Technik bis 3 Wochen", price: "50€" },
					{ name: "Wimpernverlängerung auffüllen 1:1 Technik bis 4 Wochen", price: "60€" },
					{ name: "Wimpernverlängerung 4-6D Technik", badge: "Neu", price: "85€" },
					{ name: "Wimpernverlängerung auffüllen Volumentechnik 4D-6D bis 2 Wochen", price: "45€" },
					{ name: "Wimpernverlängerung auffüllen Volumentechnik 4D-6D bis 3 Wochen", price: "55€" },
					{ name: "Wimpernverlängerung auffüllen Volumentechnik 4D-6D bis 4 Wochen", price: "65€" },
					{ name: "Wimpernverlängerung Mega Volumen 10D", price: "100€" },
					{ name: "Wimpernverlängerung entfernen", price: "15€" },
				],
			},
		],
	},
];

const PriceList = () => {
	const title = "Preisliste";
	const short_desc = "Wir bei Nails 93 & Beauty bieten Ihnen als Kunden hochwertige Produkte und moderne Techniken zu angenehmen Preisen.";

	const TableItemContent = (props) => {
		const threeCol = props.priceTitles.length === 2;

		return (
			<>
				{props.name ? (
					<h4>
						<span className="name">{props.name}</span>
						<span className="main-price">{props.main_price}</span>
					</h4>
				) : (
					<></>
				)}
				{props.desc && <div className="price-table__item--desc">{props.desc}</div>}
				<div className="price-table__item--details">
					{threeCol &&
						<div>
							<div className={threeCol ? "the-service the-service-3-col" : "the-service"}>
								<div className="the-service__name"></div>
								<div className="the-service__price">{props.priceTitles[0]}</div>
								<div className="the-service__second-price">{props.priceTitles[1]}</div>
							</div>
						</div>
					}
					{props.details?.map((the_service, service_index) => {
						return (
							<div className={threeCol ? "the-service the-service-3-col" : "the-service"} key={service_index}>
								<div className="the-service__name">{the_service.name} {the_service.badge && <Badge className="the-service__badge" bg="success">{the_service.badge}</Badge>}</div>
								<div className="the-service__price">{the_service.price}</div>
								{threeCol &&
									<div className="the-service__price">{the_service.second_price}</div>
								}
							</div>
						);
					})}
				</div>
			</>
		);
	};

	return (
		<Section id="preisliste" main_class="price-list" title={title} short_desc={short_desc}>
			<div className="main-list">
				{PRODUCTS.map((category, cat_index) => {
					return (
						<div className="main-list__category" key={cat_index}>
							<div className="main-list__category--title">
                                <img src={category.category_icon} alt={category.category_name} />
								<h3>{category.category_name}</h3>
							</div>
							<div className="main-list__category--content">
								<div className="price-table">
									{category.price_table.map((product, prod_index) => {
										return (
											<React.Fragment key={prod_index}>
												<div className={`price-table__item ${product.item_large ? 'large-col' : ''}`}>
													<TableItemContent
														name={product.item_name}
														main_price={product.item_main_price}
														desc={product.item_desc}
														priceTitles={product.item_price_titles}
														details={product.item_details}
													/>
												</div>

												{/* {product.item_extras ? (
													<div className="price-table__item">
														{product.item_extras?.map((item_extra, extra_index) => {
															return <TableItemContent key={extra_index} name={item_extra.extra_name} main_price={item_extra.extra_main_price} desc={item_extra.extra_desc} details={item_extra.extra_details} />;
														})}
													</div>
												) : (
													<></>
												)} */}
											</React.Fragment>
										);
									})}
								</div>
							</div>
						</div>
					);
				})}
			</div>
		</Section>
	);
};

export default PriceList;
