import React, { useState } from "react";
import ModalLegal from "./ModalLegal";
import "./FooterMenu.scss";

const FooterMenu = () => {
	const [modalVisible, setModalVisible] = useState(false);
	const [modalTitle, setModalTitle] = useState('');

	const display_legal_info = (e, title) => {
		e.preventDefault();
		if (title) {
			setModalTitle(title);
			setModalVisible(true);
		}
	}

	const FOOTER_MENU = {
		subscribe: {
			desc: "Folge uns auf",
			social_networks: [
				{ name: "Facebook", link: "https://www.facebook.com/nails93.leipzig", icon: "/assets/icons/facebook.png" },
				{ name: "Messenger", link: "https://m.me/nails93.leipzig", icon: "/assets/icons/messenger.png" },
				{ name: "Instagram", link: "https://www.instagram.com/nails93.leipzig/", icon: "/assets/icons/instagram.png" },
				{ name: "Whatsapp", link: "https://wa.me/+491795951910", icon: "/assets/icons/whatsapp.png" },
			],
		},
		legal: {
			legal_infos: [
				// { text: "Datenschutz", link: "#" },
				{ text: "Impressum", link: "#" },
			],
			contact_infos: [
				{ name: "Telefon", link: "tel:+4934197486640", icon: "/assets/icons/nails93-telefon-gold.svg" },
				{ name: "Email", link: "beautynailsleipzig888@gmail.com", icon: "/assets/icons/nails93-email-gold.svg" },
				{ name: "Whatsapp", link: "https://wa.me/+491795951910", icon: "/assets/icons/nails93-chat-gold.svg" },
			],
		},
		copyright: "Nails93 Leipzig - 2022",
	};
	return (
		<>
			<div className="footer-menu">
				<div className="footer-menu__links">
					<div className="footer-menu__links--subscribe">
						<div className="subscribe-desc">{FOOTER_MENU.subscribe.desc}</div>
						<div className="social-networks">
							{FOOTER_MENU.subscribe.social_networks.map((item, item_index) => {
								return (
									<a href={item.link} target="_blank" rel="noreferrer" key={item_index}>
										<img src={item.icon} alt={`Nails93 Leipzig ${item.name}`} />
									</a>
								);
							})}
						</div>
					</div>
					<div className="footer-menu__links--legal">
						<div className="contact-infos">
							{FOOTER_MENU.legal.contact_infos.map((item, item_index) => {
								return (
									<a href={item.link} target="_blank" rel="noreferrer" key={item_index}>
										<img src={item.icon} alt={`Nails93 Leipzig ${item.name}`} />
									</a>
								);
							})}
						</div>

						<div className="legal-infos">
							{FOOTER_MENU.legal.legal_infos.map((item, item_index) => {
								return (
									<a href={item.link} target="_blank" rel="noreferrer" key={item_index} onClick={(e) => display_legal_info(e, item.text)}>
										{item.text}
									</a>
								);
							})}
						</div>
					</div>
				</div>
				<div className="footer-menu__copyright">&copy; {FOOTER_MENU.copyright}</div>
			</div>
			<ModalLegal show={modalVisible} onHide={() => setModalVisible(false)} title={modalTitle} />
		</>
	);
};

export default FooterMenu;
