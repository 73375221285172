import React from "react";
import Section from "../global/Section";
import OpeningHours from "./OpeningHours";
import AppointmentButtons from "./AppointmentButtons";
import "./Appointment.scss";

const Appointment = () => {
	const title = "Jetzt Termin vereinbaren";

	return (
		<Section id="termin" main_class="appointment" title={title} background_content="#555555">
			<div className="appointment-details">
				<div className="appointment-details__without-appointment">
					<OpeningHours />
				</div>
				<div className="appointment-details__with-appointment">
					<AppointmentButtons />
				</div>
			</div>
		</Section>
	);
};

export default Appointment;
