import React from "react";
import "./OpeningHours.scss";

const OpeningHours = () => {
	const desc = "Auch ohne Termin sind Sie bei uns herzlich willkommen!";
	const icon = "/assets/icons/nails93-time-gold.svg";
	const TIME_TABLE = [
		{ day: "Mo. - Fr.", time: "09:00 - 19:00 Uhr" },
		{ day: "Samstag", time: "09:00 - 16:00 Uhr" },
		{ day: "So. & Feiertage", time: "geschlossen" },
	];
	return (
		<div className="openinghours">
			<div className="openinghours__desc">{desc}</div>
			<div className="openinghours__timetable">
				<div className="openinghours__timetable--icon">
					<img src={icon} alt="Nails93 Leipzig Öffnungszeiten" />
				</div>
				<table className="openinghours__timetable--time">
					<tbody>
						{TIME_TABLE.map((item, item_index) => {
							return (
								<tr className="timetable-item" key={item_index}>
									<td className="timetable-item__day">{item.day}</td>
									<td className="timetable-item__time">{item.time}</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default OpeningHours;
