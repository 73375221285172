import React from "react";
import "./AppointmentButtons.scss";

const AppointmentButtons = () => {
	const desc = "Vereinbaren Sie gleich einen Termin mit uns";
	const QUICK_LINKS = [
		{
			link: "tel:+4934197486640",
			icon: "/assets/icons/nails93-telefon-gold.svg",
			text: "Jetzt anrufen",
		},
		{
			link: "mailto:beautynailsleipzig888@gmail.com",
			icon: "/assets/icons/nails93-email-gold.svg",
			text: "E-Mail schreiben",
		},
	];

	return (
		<div className="appointment-buttons">
			<div className="appointment-buttons__desc">{desc}</div>
			<div className="appointment-buttons__links">
				{QUICK_LINKS.map((item, item_index) => {
					return (
						<a className="quick-links" key={item_index} href={item.link}>
							<img className="quick-links__icon" src={item.icon} alt="Nails93 Leipzig anrufen" />
							<span className="quick-links__text">{item.text}</span>
						</a>
					);
				})}
			</div>
		</div>
	);
};

export default AppointmentButtons;
