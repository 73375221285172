import React from "react";
import Container from "react-bootstrap/esm/Container";
import "./Section.scss";

/**
 * Page section
 * @param {*} props [id, main_class, extra_class, title, short_desc, background_section, background_content, children]
 * @returns Section component
 */
const Section = (props) => {
	return (
		<div id={props.id} className={`section ${props.main_class ? props.main_class : ""} ${props.extra_class ? props.extra_class : ""}`} style={{ backgroundColor: props.background_section ? props.background_section : "none" }}>
			{props.title ? (
				<Container className={`section__intro ${props.main_class ? props.main_class + "__intro" : ""}`}>
					<h2 className={`section__intro--title ${props.main_class ? props.main_class + "__intro--title" : ""}`}>{props.title}</h2>
					{props.short_desc ? <div className={`section__intro--short-desc ${props.main_class ? props.main_class + "__intro--short-desc" : ""}`}>{props.short_desc}</div> : <></>}
				</Container>
			) : (
				<></>
			)}
			<div className={`section__wrapper ${props.main_class ? props.main_class + "__wrapper" : ""}`} style={{ backgroundColor: props.background_content ? props.background_content : "none" }}>
				<div className={`section__wrapper--content ${props.main_class ? props.main_class + "__wrapper--content" : ""}`}>{props.children}</div>
			</div>
		</div>
	);
};

export default Section;
