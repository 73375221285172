import React from "react";
import "./FooterInfo.scss";

const FooterInfo = () => {
    const FOOTER_INFO = {
        logo: "/assets/logos/Nails93-Logo_v3.svg",
        slogan: "Only in Leipzig"
    }
	return (
        <div className="footer-info">
            <div className="footer-info__logo">
                <img src={FOOTER_INFO.logo} alt="Nails 93 Leipzig" />
            </div>
            
            <div className="footer-info__slogan">{FOOTER_INFO.slogan}</div>
        </div>
    );
};

export default FooterInfo;
